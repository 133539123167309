<template>
  <v-row class="pb-12" v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Proveedores" :breadcrumbs="breadcrumbs" :dense="true" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasPermission(['view_supplier'])">
        <v-tooltip color="white-0" right max-width="185px" transition="scale-transition" open-delay="1000">
          <template v-slot:activator="{ on }">
            <span class="grey-700--text body-1" v-on="on">
              <v-btn class="mr-2" width="35" outlined @click="mode = !mode" :disabled="!suppliersList.length || Object.keys($route?.query).length === 0">
              <v-icon size="19">mdi-{{ !mode ? 'view-grid-outline' : 'table'}}</v-icon>
            </v-btn>
            </span>
          </template>
          <span class="d-block px-3 py-2">{{ mode ? 'Ver modo tabla' : 'Ver modo grilla' }}</span>
        </v-tooltip>
        <v-query-builder :query-map="filters" model="suppliers" :active.sync="activeFilter" />
        <v-btn class="ml-2" color="blue-500" @click="dialogSupplier=true"><v-icon left>mdi-human-dolly</v-icon>Crear proveedor</v-btn>
      </template>
    </mini-header>
    <!-- end header -->

    <v-col cols="12" class="px-0">
      <skeleton-list v-if="loading" />
      <template v-else>
        <PermissionDenied v-if="!$helpers.hasPermission(['view_supplier'])" />
        <template v-else>
          <v-empty-state v-if="!suppliersList.length || Object.keys($route?.query).length === 0" type="suppliers" id="proveedores" :hiddenDescription="hiddenBtnEmpty" customClass="mt-3">
            <template v-slot:actions>
              <template v-if="!$route.query.ordering && hiddenBtnEmpty">
                <v-btn @click="activeFilter = true" color="blue-500" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
                <v-btn class="ml-2" @click="latestRecords()" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
              </template>
            </template>
          </v-empty-state>
          <template v-else class="text-center py-5">
          <v-data-table
            v-if="!mode"
            :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
            :headers="headers"
            :items="suppliersList"
            :items-per-page="pagination"
            mobile-breakpoint
            hide-default-header
            disable-sort
            hide-default-footer
            item-key="id"
            @click:row="(suppliersList) => {$router.push({name: 'SupplierRetrieveUpdate', params: {id: suppliersList.id}})}"
            style="cursor: pointer"
            fixed-header
            :height="heightTable"
          >
            <!-- header -->
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders
                :headers="headers"
                @sort="setSort"
              />
              <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
            </template>
            <!-- end header -->
            <template v-slot:[`item.name`]="{item}">
              <span class="grey-500--text font-weight-medium">{{item.name}}</span>
            </template>
            <template v-slot:[`item.tax_settings`]="{item}">
              <template v-if="item.tax_settings?.cl_activity_description">{{item.tax_settings?.cl_activity_description}}</template>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>
             <template v-slot:[`item.email`]="{item}">
              <template v-if="item.email">{{item.email}}</template>
              <span class="body-2 text--disabled" v-else>Sin información</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
              <span class="text-right">{{item.created | dateTimeSecond}}</span>
            </template>
          </v-data-table>
          <v-row v-else>
            <v-col v-for="(supplier, index) in suppliersList" :key="`${supplier.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat :to="{ name: 'SupplierRetrieveUpdate', params: { id: supplier.id }}" @click="$store.commit('base/SET_QUERY', $route.query)">
                  <v-card-actions>
                  </v-card-actions>
                  <v-row no-gutters>
                    <v-icon color="blue-500">mdi-human-dolly</v-icon>
                    <v-spacer />
                    <!-- <v-checkbox class="mr-n2" v-model="selected" :value="supplier.id" dense multiple hide-details /> -->
                  </v-row>
                  <span class="d-block mt-2">
                    <v-sheet class="transparent" height="25">
                      <span v-if="supplier.name.length" class="grey-700--text font-weight-medium body-1 d-inline-block text-truncate" style="max-width: 162px;">{{supplier.name}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-sheet>
                  </span>
                  <span v-if="supplier.tax_id" class="mt-n2 d-block body-1 grey-300--text d-inline-block text-truncate" style="max-width: 180px;">{{supplier.tax_id}}</span>
                  <span class="d-block mt-n1 body-2 text--disabled font-italic" v-else>Sin especificar</span>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
            <VMainPagination titleShowAll="Ver todos los proveedores" :count="count" redirect="SupplierListCreate" />
          </template>
        </template>
      </template>
    </v-col>

    <!-- dialog new supplier --
    <v-dialog v-model="dialogSupplier" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-medium">Crear proveedores</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogSupplier=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5">
          <v-list class="v-list-form-2 pa-0 transparent">
            <v-list-item class="pa-0">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-col class="pa-0">
                  <v-text-field v-model.trim="$v.newSupplier.name.$model" :error="$v.newSupplier.name.$error" outlined required single-line dense placeholder="Acme LLC" maxlength="100" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <!- <v-list-item class="pa-0">
              <v-list-item-title>País</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-select v-model="$v.newSupplier.country.$model" :error="$v.newSupplier.country.$error" single-line :items="countriesList" item-text="name" item-value="id" hide-details dense height="30" outlined />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>Estado</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-select v-model="$v.newSupplier.state.$model" :error="$v.newSupplier.state.$error" :items="$store.getters['base/currentStates']" item-text="name" item-value="id" single-line hide-details dense height="30" outlined no-data-text="No existen estados para el país." />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-title>Dirección</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model.trim="$v.newSupplier.address.$model" :error="$v.newSupplier.address.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-4">
              <v-list-item-title>
                <span class="grey-500--text font-weight-medium">Información fiscal</span>
              </v-list-item-title>
            </v-list-item> --
            <v-list-item class="px-0 pt-2">
              <v-list-item-title>RUT</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-col class="pa-0">
                  <v-text-field v-model.trim="$v.newSupplier.tax_id.$model" :error="$v.newSupplier.tax_id.$error" v-dni="newSupplier.country" outlined single-line dense hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="pa-0 pt-2" v-if="country && country.id === 'CL'">
              <v-list-item-title>Actividad económica</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-text-field v-model.trim="$v.newSupplier.tax_settings.cl_activity_description.$model" :error="$v.newSupplier.tax_settings.cl_activity_description.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <!- <v-list-item class="mt-4">
              <v-list-item-title>
                <span class="grey-500--text font-weight-medium">Información de contacto</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-title>Email de emisión</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.newSupplier.email.$model" :error="$v.newSupplier.email.$error" outlined required single-line dense maxlength="64" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Teléfono</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.newSupplier.phone.$model" :error="$v.newSupplier.phone.$error" outlined required single-line dense v-if="country" v-mask="`${country.phone_code} # #### ####`" maxlength="16" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Sitio web</v-list-item-title>
              <v-list-item-subtitle class="pa-0">
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.newSupplier.website.$model" :error="$v.newSupplier.website.$error" outlined required single-line class="pa-0" dense  maxlength="64" :hide-details="true" />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item> --
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogSupplier=false">Cancelar</v-btn>
          <v-btn color="blue-500" :loading="$store.state.suppliers.createLoader" @click="postSupplier">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!----- -->
    <v-dialog v-model="dialogSupplier" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Crear proveedor</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogSupplier=false, clearInput()" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-0 overflow-y-auto" style="max-height: 710px;" min-height="700" scrollable>
          <v-row class="px-5" no-gutters>
            <v-col cols="3" class="pa-0">
              <span class="grey-700--text body-1">{{$t('generals.RUT')}}</span>
              <v-text-field v-model.trim="$v.newSupplier.tax_id.$model" :error="$v.newSupplier.tax_id.$error" v-dni="newSupplier.country" required outlined single-line dense hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="grey-700--text body-1">Nombre del proveedor</span>
              <v-text-field v-model.trim="$v.newSupplier.name.$model" :error="$v.newSupplier.name.$error" outlined required single-line dense maxlength="100" hide-details />
            </v-col>
          </v-row>
          <v-row class="px-5 py-3" no-gutters>
            <v-col cols="12" class="pa-0">
              <span class="grey-700--text body-1">Actividad económica</span>
              <v-text-field v-model.trim="$v.newSupplier.tax_settings.cl_activity_description.$model" :error="$v.newSupplier.tax_settings.cl_activity_description.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <!-- <v-col class="pa-0 ml-3">
              <span class="grey-700--text body-1">Correo de emisión</span>
              <v-text-field v-model.trim="$v.newSupplier.email.$model" :error="$v.newSupplier.email.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col> -->
          </v-row>
          <v-row class="px-5 pb-0" no-gutters>
            <v-col cols="8" class="pa-0">
              <span class="grey-700--text body-1">Dirección</span>
              <v-text-field v-model.trim="$v.newSupplier.address.$model" :error="$v.newSupplier.address.$error" outlined required single-line dense maxlength="64" hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="grey-700--text body-1">{{$t('generals.Comuna')}}</span>
              <v-select v-model="$v.newSupplier.state.$model" :error="$v.newSupplier.state.$error" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" single-line hide-details dense height="30" outlined :no-data-text="`No existen ${$t('generals.Comuna')} para el país.`">
                <template v-slot:item="data">
                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 grey-700--text font-weight-semibold">
              Agregar contactos
              <v-chip class="ml-2 pa-2" x-small color="grey-background">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in contacts" :key="i">
              <div class="py-1 d-flex justify-space-between">
                <div class="col-4 pa-0">
                  <span class="grey-700--text body-1">Nombre</span>
                  <v-text-field v-model.trim="d.name" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-4 py-0 px-1">
                  <span class="grey-700--text body-1">Email</span>
                  <v-text-field v-model="$v.contacts.$each[i].email.$model" :error="$v.contacts.$each[i].email.$error" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <div class="col-3 pa-0" style="max-width: 290px;">
                  <span class="grey-700--text body-1">Teléfono</span>
                  <v-text-field v-model.trim="d.phone" v-mask="`${d.phone} # #### ####`" maxlength="16" outlined required single-line dense hide-details />
                </div>
                <div class="col-1 pa-0" style="max-width: 30px;">
                  <v-btn class="mt-5" v-if="i >= 1 || contacts.length > 1" @click="contacts.splice(i, 1)" text color="grey-500" :ripple="false"><v-icon color="blue-500">mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </div>
            <v-btn v-if="contacts.length < 7" @click.prevent="addRowContacts" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otro</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogSupplier=false, clearInput()">Cancelar</v-btn>
          <v-btn color="blue-500" :loading="$store.state.suppliers.createLoader" @click="postSupplier">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new supplier -->

    <!-- dialog confirm list -->
    <v-dialog v-model="dialogConfirm" width="600" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-medium">Cambio de lista</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfirm=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-700--text">
          <span class="d-block">Al cambiar a la lista blanca, todos los documentos de estos proveedores, serán aceptados automáticamente.</span>
          <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
          <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogConfirm=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="handleList" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm list -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength, email, requiredIf } from 'vuelidate/lib/validators'
import { rutValidator } from 'vue-dni'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VEmptyState from '@/components/commons/VEmptyState'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    VQueryBuilder,
    VTableHeaders,
    VEmptyState,
    SkeletonList,
    VMainPagination,
    MiniHeader,
    PermissionDenied
  },
  mixins: [
    GenericViewMixin,
    ListViewMixin
  ],
  data: () => ({
    activeFilter: false,
    welcome: true,
    mode: true,
    scroll: 0,
    confirm: '',
    isAccept: false,
    dialogConfirm: false,
    currentList: [],
    current: {},
    newSupplier: {
      name: '',
      tax_id: '',
      tax_settings: {
        cl_activity_description: ''
      },
      state: '',
      address: ''
    },
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        category: 'a',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'tax_id',
        label: 'RUT',
        value: null,
        category: 'a',
        operators: [],
        countries: ['PE', 'CL']
      }
      // {
      //   type: 'date',
      //   id: 'created',
      //   value: null,
      //   value2: null,
      //   category: 'b',
      //   label: 'Fecha de creación',
      //   operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      // },
      // {
      //   type: 'radio',
      //   id: 'sources',
      //   value: null,
      //   label: 'Método de pago',
      //   category: 'c',
      //   choices: [{label: 'Si', value: 'True'}, {label: 'No', value: 'False'}]
      // },
      // {
      //   type: 'text',
      //   id: 'email',
      //   label: 'Email',
      //   value: null,
      //   category: 'c',
      //   operators: ['contiene']
      // },
      // {
      //   type: 'text',
      //   id: 'country__name',
      //   label: 'País',
      //   value: null,
      //   category: 'c',
      //   operators: ['contiene']
      // }
    ],
    headers: [
      { text: 'Proveedor', value: 'name', class: 'font-weight-bold', sortable: true },
      { text: 'RUT', value: 'tax_id', sortable: true },
      { text: 'Actividad económica', value: 'tax_settings', sortable: true },
      // { text: 'Email', value: 'email', sortable: true },
      { text: 'Creado el', value: 'created', align: 'end', sortable: true }
      // { text: 'Lista', value: 'list', align: 'end', sortable: true },
    ],
    dialogSupplier: false,
    loading: false,
    options: {
      white: ['Sacar de lista blanca', 'Cambiar a lista negra'],
      black: ['Sacar de lista negra', 'Cambiar a lista blanca'],
      null: ['Cambiar a lista blanca', 'Cambiar a lista negra']
    },
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Proveedores'
        }
      ]
    },
    contacts: [{ name: '', email: '', phone: '' }]
  }),
  computed: {
    ...mapState({
      count: state => state.suppliers.suppliersCount,
      suppliersList: state => state.suppliers.suppliersList,
      countriesList: state => state.base.countriesList
    }),
    country () {
      return this.$store?.state?.auth?.account?.country ? this.countriesList.find(country => country.id === this.$store?.state?.auth?.account?.country) : null
    },
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!this.suppliersList.length) return false
      return false
    },
    heightTable () {
      if (this.$vuetify.breakpoint.height >= 1200) return 'auto'
      return 'calc(100vh - 210px)'
    },
    currentHeader () {
      return this.headers.forEach((item) => {
        if (item.value === 'tax_id') {
          item.text = this.$t('generals.RUT')
        }
      })
    }
  },
  watch: {
    'newSupplier.country' () {
      this.newSupplier.state = ''
      this.newSupplier.tax_id = ''
    }
  },
  mounted () {
    this.$root.$on('isClose', () => {
      this.isClosePanel = true
    })
  },
  created () {
    if ((typeof this.getList === 'function' && Object.keys(this.$route?.query).length && this.$helpers.hasPermission(['view_supplier']))) {
      this.getList()
    }
  },
  methods: {
    addRowContacts () {
      this.contacts.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'SupplierRetrieveUpdate', params: { id: id }})
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    handleConfirm () {
      if (['Cambiar a lista blanca', 'Cambiar a lista negra'].includes(this.currentList[0])) {
        this.dialogConfirm = true
      } else {
        this.handleList()
      }
    },
    handleList () {
      const index = this.suppliersListPE.findIndex(({id}) => id === this.currentList[1].id)
      if (['Cambiar a lista blanca', 'Cambiar a lista negra'].includes(this.currentList[0])) {
        this.suppliersListPE[index].list = this.currentList[1].list === 'white' ? 'black' : 'white'
      } else {
        this.suppliersListPE[index].list = null
      }
      this.dialogConfirm = false
    },
    async getList () {
      this.welcome = false
      if (this.$store?.state?.auth?.account?.country !== 'PE') {
        if (!this.isClosePanel) {
          this.loading = true
          await this.$store.dispatch('suppliers/LIST', {
            resource: 'suppliers',
            query: this.$route.query
          })
          this.loading = false
        }
        this.isClosePanel = false
      }
    },
    postSupplier () {
        this.$v.newSupplier.$touch()
        if (this.$v.newSupplier.$invalid) {
          return false
        }
      if (this.contacts[0].phone?.length || this.contacts[0].name?.length) {
        this.$v.contacts.$touch()
        if (this.$v.contacts.$invalid) return false
      }
      this.$store.dispatch('suppliers/CREATE', {
        resource: 'suppliers',
        payload: { ...this.newSupplier, country: this.$store?.state?.auth?.account?.country }
      })
      .then(async (response) => {
         await Promise.all(this.contacts.map(async (item) => {
          if (item.email.length || item.name.length || item.phone.length) {
            this.$store.dispatch('suppliers/CREATE', {
              resource: 'suppliers/contacts',
              payload: {...item, ...{ supplier: response.data.id }}
            })
          }
         }))
          this.dialogSupplier = false
          this.$router.push({ name: 'SupplierRetrieveUpdate', params: { id: response.data.id } })
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    clearInput () {
      this.newSupplier = {
        name: '',
        tax_id: '',
        tax_settings: {
          cl_activity_description: ''
        }
      }
      this.contacts = [{ name: '', email: '', phone: '' }]
      this.$v.newSupplier.$reset()
      this.$v.contacts.$reset()
    }
  },
  validations: {
    newSupplier: {
      name: { required, maxLength: maxLength(100) },
      state: { required },
      address: { required, maxLength: maxLength(64) },
      tax_id: {
        required,
        format: function (val) {
          if (this.$store?.state?.auth?.account?.country === 'CL') {
            return rutValidator(val)
          }
          return true
        },
        maxLength: maxLength(32)
      },
      tax_settings: {
        cl_activity_description: {
          required: requiredIf(function () {
            return this.$store?.state?.auth?.account?.country === 'CL'
          }),
          maxLength: maxLength(64)
        }
      }
    },
    contacts: {
      $each: {
        email: {
         required,
          email,
          maxLength: maxLength(64)
        }
      }
    }
  }
}
</script>